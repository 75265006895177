import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import settingModel from "@/models/setting.model";
import { useRequest } from "ahooks";
import styles from "./index.module.css";

export default () => {
  const res = useRequest(() => {
    return settingModel.getItem("agreement").then((res) => res.data);
  });
  return (
    <div className={styles.page}>
      <NavBar title="用户协议" />
      {res.loading ? (
        <Loader />
      ) : (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: res.data }}
        />
      )}
    </div>
  );
};
